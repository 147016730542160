<template>
  <div class="columns">
      <div class="column is-one-third">
        <card-component
          title="1. Set options to extract a new dataset"
          class="tile is-child"
          :displayHelp="$options.name"
        >
          <validation-observer v-slot="{ handleSubmit }">
            <b-field
              label="Criterion"
            >
              <b-select
                v-model="criterion"
                placeholder="Dataset type"
                class="mb-2"
                expanded
                icon="asterisk"
                @input="computeDatasetName()"
              >
                <option value="axesCP">Components, by number</option>
                <option v-if="!analysisType.startsWith('pls')" value="axesCumVar">Components, by explained variance</option>
                <option value="correlation">Variables</option>
              </b-select>
            </b-field>

            <validation-provider
              v-slot="{ errors, valid}"
              :rules= {required:true,integer:true,min_value:1,max_value:nbCP}
              name="ncp"
              v-if="criterion==='axesCP'||criterion==='correlation'"
            >
              <b-field
                label="Number of components"
                :type="{ 'is-danger': errors[0], 'is-success': valid }"
                :message="errors"
              >
                <b-input
                  v-model="ncp"
                  @focus="$event.target.select()"
                  @input="computeDatasetName()"
                  expanded
                ></b-input>
              </b-field>
            </validation-provider>

            <b-field label="Threshold correlation" v-if="criterion==='correlation'">
              <b-slider
                v-model.lazy="thresholdCorExtract"
                :min=minThreshold
                :max=1
                :step=0.05
                :tooltip=true
                :indicator=true
                type="is-grey"
                @change="computeDatasetName()"
              >
                <b-slider-tick :value="0">0</b-slider-tick>
                <b-slider-tick :value="1">1</b-slider-tick>
              </b-slider>
            </b-field>

            <b-field label="Cumulative percentage of explained variance" v-if="criterion==='axesCumVar'">
              <b-slider
                v-model.lazy="cumVar"
                :min=0
                :max=100
                :step=1
                :tooltip=true
                :indicator=true
                type="is-grey"
                @change="computeDatasetName()"
              >
                <b-slider-tick :value="0">0</b-slider-tick>
                <b-slider-tick :value="100">100</b-slider-tick>
              </b-slider>
            </b-field>

            <br v-else/>
            <div v-if="!extractedSuccess">
              <validation-provider
                v-slot="{ errors, valid }"
                :rules= {excluded:datasetsNames,does_not_contains:true,authorize_name:true,not_too_long:true}
                :customMessages = "{excluded: 'This dataset name has already been used. Choose another one.',
                does_not_contains: 'The dataset name should not contain special symbols (except underscore) nor start with numbers.',
                authorize_name: 'This dataset name is not allowed',
                not_too_long: 'This dataset name is too long'}"
                name="Dataset name"
              >
                <b-field
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                  label="Dataset name"
                >
                  <b-input
                    v-model="datasetNameExtract"
                    expanded
                    icon="tag"
                  ></b-input>
                </b-field>
              </validation-provider>
            </div>
            <hr />
            <b-notification
              v-if="extracted && extractedSuccess"
              type="is-success"
              aria-close-label="Close notification"
              role="alert"
            >
            Dataset successfully extracted.
            </b-notification>
            <b-notification
              v-if="extracted && !extractedSuccess"
              type="is-danger"
              aria-close-label="Close notification"
              role="alert"
            >
            Error while extracting dataset.
            </b-notification>
            <div v-if="!extractedSuccess" class="has-text-centered">
              <b-button rounded
                class="is-primary button-shadow"
                @click.prevent="handleSubmit(run)"
              >
                <span class="icon is-small"><i class="fa fa-cogs"></i></span>
                <span> Extract new dataset </span>
              </b-button>
            </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component
            v-if="!resultsLoaded"
            title="2. How to extract a new dataset?"
          >
          </card-component>
          <card-no-result v-if="!resultsLoaded">
            <strong>How to set options?<br></strong>
            <p>Set options in left panel to generate a new dataset from the analysis:
              <ul>
                <li>with the criterion “<strong>Components, by number</strong>”,
                the first components (number to be specified by the user) will
                be extracted and used as a new dataset;</li>
                <li>with the criterion “<strong>Components, by explained
                variance</strong>” (when available), the first components
                (number automatically set to reach the targeted percentage of
                explained variance or a targeted correlation ratio for
                categorical variables) will be extracted and used as a new
                dataset;</li>
                <li>with the criterion “<strong>Variables</strong>”, the
                variables the most correlated with the first components will be
                selected and used as a new dataset.</li>
              </ul>
            </p>
          </card-no-result>
          <div v-if="newDatasetName!==''">
            <error :type='"notifications"' />
            <dataset-view :dataset-name="newDatasetName" />
          </div>
        </div>
  </div>
</template>

<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { ranalysis } from '@/mixins/ranalysis'
import apiService from '@/services/apiService'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RExtractObj',
  props: {
    analysisType: {
      type: String,
      default: ''
    },
    datasetName: {
      type: String,
      default: ''
    },
    datasetPrefix: {
      type: String,
      default: ''
    },
    extractSuffix: {
      type: String,
      default: ''
    },
    nbCP: {
      type: Number,
      default: 0
    }
  },
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-view': () => import('@/components/DatasetView.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      criterion: 'axesCP',
      ncp: 5,
      cumVar: 50,
      thresholdCorExtract: 0.5,
      datasetUserName: 'extr',
      datasetNameExtract: '',
      newDatasetName: '',
      newDatasetUserName: '',
      extracted: false,
      extractedSuccess: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    minThreshold: function () {
      return (this.criterion === 'correlation' ? 0.05 : 0)
    },
    datasetsNames: function () {
      return this.workspaceStore.datasets.map(dataset => dataset.user_name)
    },
    titleResult: function () {
      return '2. New dataset : ' + this.newDatasetUserName
    },
    params: function () {
      let funcName = 'r_extract_obj'
      if (this.analysisType.startsWith('pls')) {
        funcName = 'r_extract_pls'
      }
      let params = {
        'func_name': funcName,
        'datasetName': this.datasetName,
        'criterion': this.criterion,
        'out_graph': true
      }
      if (this.criterion === 'correlation') {
        params['threshold.cor'] = this.thresholdCorExtract
        params['ncp'] = parseInt(this.ncp)
      } else if (this.criterion === 'axesCumVar') {
        params['cumVar'] = this.cumVar
        params['criterion'] = 'axes'
      } else {
        params['criterion'] = 'axes'
        params['ncp'] = parseInt(this.ncp)
      }
      if (this.datasetNameExtract !== '') {
        params['userName'] = this.datasetNameExtract
      }
      return params
    }
  },
  mounted () {
    extend('does_not_contains', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.match(/^[a-zA-Z0-9_]*$/) && !value.match(/^\d/)
    })
    extend('not_too_long', {
      getMessage: field => 'The ' + field + ' value is not truthy.',
      validate: value => value.length < 40
    })
    extend('authorize_name', (value) => {
      return apiService.runRFunction({
        'func_name': 'r_forbidden_name',
        'username': value
      }).then((res) => {
        return {
          valid: !res.forbidden
        }
      }, (err) => {
        return {
          valid: false,
          data: { message: 'This dataset name is forbidden.' + err }
        }
      })
    })

    const loadingComponent = this.$buefy.loading.open({
      container: null
    })
    this.workspaceStore.getWorkflow()
      .then(() => {
        loadingComponent.close()
      })
      .catch(() => {
        loadingComponent.close()
      })
    this.computeDatasetName()
  },
  watch: {
    datasetName: function () {
      this.computeDatasetName()
    },
    datasetNameExtract: function () {
      this.extracted = false
      this.extractedSuccess = false
    }
  },
  methods: {
    computeDatasetName: function () {
      let name = this.datasetPrefix
      if (this.criterion === 'correlation') {
        name = this.datasetPrefix + '_sel_' + this.ncp + '_cor' + this.thresholdCorExtract + '_' + this.extractSuffix
      } else {
        if (this.criterion === 'axesCumVar') {
          name = this.datasetPrefix + '_axes_cum' + this.cumVar + '_' + this.extractSuffix
        } else {
          name = this.datasetPrefix + '_axes_ncp' + this.ncp + '_' + this.extractSuffix
        }
      }
      this.datasetNameExtract = name
    },
    afterRun: function (data) {
      this.newDatasetName = data.ObjectName
      this.newDatasetUserName = data.UserName
      // this.datasetNameExtract = ''
      // this.criterion = 'axesCP'
      // this.ncp = 5
      // this.cumVar = 50
      // this.thresholdCorExtract = 0.5
      this.extracted = true
      this.extractedSuccess = true
    }
  }
}
</script>
<style scoped>
ul {
  list-style: inside;
  padding-left: 10px;
}
.b-slider .b-slider-track {
    height: 0.5rem;
    background-color: #20BFF0;
}
</style>
